<template>
  <div>
    <select-shop :loadingButton="loadingButton" v-on:getData="getProduct"></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody style="padding: 1.55rem">
          <div class="row mb-3">
            <div v-if="!isEcommercePage" class="col-md-10 col-sm-9 col-8 d-inline-flex align-items-center">
              <h2 class="font-weight-normal">
                {{ $t("management.productManagement") }}
              </h2>
              <HelpButton :isHeader="false" class="ml-2 mb-2"></HelpButton>
            </div>
            <div v-else class="col-md-10 col-sm-9 col-8">
              <h2 class="font-weight-normal" style="display: inline;">
                {{ $t("ecomProduct") }}
              </h2>
              <img src="/img/shopee.png" alt="Shopee Logo" style="width: 32px; height: 32px; display: inline; margin-top: -15px;"/>
            </div>
            <div v-if="isEditData" class="col-md-2 col-sm-3 col-4 text-right text-success">
              <div v-if="!isEcommercePage"> 
                <router-link
                    v-show="isEditData"
                    style="text-decoration:none;"
                    :to="{
                      name: 'newAddproduct',
                      query: queryparams,
                    }">
                    <CButton block color="success" style="display: flex; align-items: center; justify-content: center;">
                      <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>
                  {{ $t('addProduct') }} 
                </CButton> 
                </router-link>
              </div>
              <div v-else>
                <div class="col-md-2 col-sm-3 col-4 text-right">
                <CButton block color="outline-success" @click="selectProduct()">
                  <i class="fa-solid fa-rotate"></i>
                  {{ $t("sync") }}
                </CButton>
              </div>
              </div>
            </div>
              <div v-else-if="isEcommercePage" class="col-md-2 col-sm-3 col-4 text-right text-success">
                <router-link
                    style="text-decoration:none;"
                    :to="{
                      name: 'AddProductEcommerce',
                      query: queryparams,
                    }">
                    <CButton block color="success" style="display: flex; align-items: center; justify-content: center;">
                      <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>
                  {{ $t('addProduct') }}
                </CButton> 
                </router-link>
                <div class="mt-3">
                <CButton block color="outline-success" @click="selectProduct()">
                  <i class="fa-solid fa-rotate"></i>
                  {{ $t("sync") }}
                </CButton>
              </div>
              </div>
          </div>
          <search-pattern
            class="mb-4"
            :keyword="keyword"
            :searchBy="searchBy"
            v-on:getSort="setSearch"
          ></search-pattern>
          <CRow class="justify-content-between">
            <CCol>
              <h5 class="font-weight-normal col-form-label h5">
                {{ $t("product") }} {{ countProduct }} {{ $t("item") }}
              </h5>
            </CCol>
            <CCol class="col-4">
              <div class="btn-group btn-group-toggle row view-list" data-toggle="buttons">
                <label
                  class="btn form-check-label col-lg-6 col-6 btn-ghost-secondary view-icon"
                  style="padding: 0%;"
                  :class="{ active: showView === 'list' }"
                >
                  <input
                    type="radio"
                    name="options"
                    id="option1"
                    autocomplete="off"
                    class="custom-control-input cursor"
                    :value="showView === 'list'"
                    :active="showView === 'list'"
                    :checked="this.showView === 'list'"
                    @change="showView = 'list'"
                  />
                  <div class="mt-1"><i class="fi fi-rr-list" style="font-size: 14px;"></i>
                  </div>
                  
                </label>
                <label
                  class="btn form-check-label col-lg-6 col-6 btn-ghost-secondary view-icon"
                  style="padding: 0%;"
                  :class="{ active: showView === 'grid' }"
                >
                  <input
                    type="radio"
                    name="options"
                    id="option1"
                    autocomplete="off"
                    class="custom-control-input cursor"
                    :value="showView === 'grid'"
                    :active="showView === 'grid'"
                    :checked="this.showView === 'grid'"
                    @change="showView = 'grid'"
                  />
                  <div class="mt-1"><i class="fi fi-rr-apps" style="font-size: 14px;" ></i>
                  </div>
                </label>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" lg="12">
              <sortdata
                v-on:getSort="setSort"
                :sort_value="sort_value"
                :sortdata="sortdata"
                class="mb-3"
              />
              <div v-if="showView === 'list'" class="mb-3" style="overflow-x: auto">
                <table class="table table-striped" style="width: 100%; ">
                  <thead>
                    <tr class="font-weight-normal">
                        <th style="width: 15%" class="text-dark font-weight-normal"></th>
                        <th style="width: 25%; padding-left: 15px;" class="text-dark font-weight-normal">{{ $t('product') }}</th>
                        <th style="width: 10%" class="text-dark font-weight-normal">{{ $t('category') }}</th>
                        <th style="width: 10%" class="text-dark text-right font-weight-normal">{{ $t('price') }}</th>
                        <th style="width: 10%" class="text-dark font-weight-normal">{{ $t('productType') }}</th>
                        <th style="width: 10%" class="text-dark font-weight-normal">{{ $t('vatType') }}</th>
                        <th style="width: 10%" class="text-dark font-weight-normal"></th>
                      </tr>
                  </thead>
                  <tbody class="text-dark">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>
                        <div class="display">
                            <div class="display-type" v-if="item.favorite">
                              <CBadge color="light" shape="pill" style="background-color: #f8f9fa; padding: 2px; border-radius: 50%; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center;">
                                <i class="fi fi-rr-circle-heart" style="color: lightcoral; font-size: 16px; margin: auto;"></i>
                              </CBadge>
                            </div>
                            <img
                              v-if="item.remoteImagePath"
                              :src="item.remoteImagePath"
                              class="img-fluid rd"
                              style="border-radius: 3px"
                              @error="noImgUrl"
                            />
                            <div
                              v-else
                              class="image-placeholder"
                              :style="{
                                'aspect-ratio': '4 / 3',
                                backgroundColor: item.indexColor,
                                'border-radius': '3px'
                              }"
                            ></div>
                          </div>
                      </td>
                      <td style="padding-left: 15px">{{ item.SKUName }} 
                        
                         ( {{ item.unit }} ) 
                         <img v-if="item.sourceGRAB === 'GRAB'" src="/img/delivery/grab.png" class="rounded-circle"
                         style="width: 20px;" />
                        
                      </td>
                      <td >{{ item.category }}</td>
                      <td class="text-right">{{ item.price }}</td>
                      <td class="text-center">{{ item.skuType }}</td>
                      <td class="text-center">{{ item.vatType }}</td>
                      <td><img src="/img/file-edit.png" style="height: 18px; width:18px; margin-right:20px; cursor: pointer" @click="productDetail(item)">
                      </img>
                      <img src="/img/trash.png" style="height: 18px; width:18px; cursor: pointer" v-if="isDeleteData" @click="confirmdelete(item.objectId)">
                      </img></td>
                    </tr>
                  </tbody>
                </table>
                <!-- <CListGroup v-if="loadingButton === true">
                  <CListGroupItem
                    v-for="(item, index) in items"
                    :key="index"
                    class="list-group-item-action cursor"
                    v-if="!isEcommercePage"
                  >
                    <router-link
                      style="text-decoration: none"
                      :to="{
                        name: 'ProductDetail',
                        params: { productSKUObjectId: item.objectId },
                        query: queryparams,
                      }"
                    >
                      <div class="row">
                        <div class="col-md-2">
                          <div class="display">
                            <div class="display-type" v-if="item.favorite">
                              <CBadge color="light" shape="pill" style="background-color: #f8f9fa; padding: 2px; border-radius: 50%; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center;">
                                <i class="fi fi-rr-circle-heart" style="color: lightcoral; font-size: 16px; margin: auto;"></i>
                              </CBadge>
                            </div>
                            <img
                              v-if="item.remoteImagePath"
                              :src="item.remoteImagePath"
                              class="img-fluid rd"
                              style="border-radius: 3px"
                              @error="noImgUrl"
                            />
                            <div
                              v-else
                              class="image-placeholder"
                              :style="{
                                'aspect-ratio': '4 / 3',
                                backgroundColor: item.indexColor,
                                'border-radius': '3px'
                              }"
                            ></div>
                          </div>
                        </div>

                        <div class="col-md-8">
                          <h6 class="font-weight-normal text-dark">
                            {{ item.SKUName }} ( {{ item.unit }} )
                          </h6>
                          <h6 class="text-dark mt-3">
                            {{ item.price }}
                            <CBadge
                              v-if="item.isOnScreen"
                              color="success"
                              shape="pill"
                              class="font-weight-normal"
                            >
                              {{ $t("isOnScreen") }}
                            </CBadge>
                            <span class="text-white">:</span>
                            <CBadge
                              v-if="item.isMenu"
                              color="info"
                              shape="pill"
                              class="font-weight-normal"
                            >
                              {{ $t("isMenu") }}
                            </CBadge>
                          </h6>
                          <span v-if="checkStdCost(item)" class="text-danger" style="font-size: 10px;">
                            * {{ $t('stdCostWarning') }}
                          </span>
                        </div>

                        <div class="col-md-2 text-right">
                          <h6 class="text-dark text-right mb-3">
                            <b class="text-dark font-weight-normal">{{ item.category }}</b>
                          </h6>
                          <span class="text-muted">
                            <b class="text-dark font-weight-normal">{{ $t("vat") }}: </b>
                            {{ item.vatType }}
                          </span>
                          <div class="text-muted">
                            <b class="text-dark font-weight-normal">{{ $t("productType") }}: </b>
                            {{ item.skuType }}
                          </div>
                          <td style="width: 1%" class="text-right text-dark jusity-content-end">
                            <img src="/img/file-edit.png" style="height: 18px; width:18px" @click="productDetail(item)">
                            </img>
                          </td>
                        </div>
                        
                      </div>
                    </router-link>
                  </CListGroupItem>
                </CListGroup> -->
                <table v-if="isEcommercePage" class="table" style="width: 100%; border-collapse: collapse;">
                  <thead>
                    <tr>
                      <th class="width: 20%"></th>
                      <th class="width: 20%">{{ $t('product') }}</th>
                      <th class="width: 10%">{{ $t('unit') }}</th>
                      <th class="width: 10%">{{ $t('price') }}</th>
                      <th class="width: 10%">{{ $t('productType') }}</th>
                      <th class="width: 20%">{{ $t('category') }}</th>
                      <th class="width: 5%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td class="width: 5%">
                        <div class="display" style="position: relative; text-align: left;">
                          <div 
                            class="display-type" 
                            v-if="item.favorite" 
                            style="position: absolute; top: 5px; right: 60px; z-index: 10;"
                          >
                            <CBadge
                              color="light"
                              shape="pill"
                              style="color: lightcoral;"
                            >
                            <i class="fi fi-rr-circle-heart" style="color: lightcoral; font-size: 16px; margin: auto;"></i>
                          </CBadge>
                          </div>
                          <img
                            v-if="item.remoteImagePath"
                            :src="item.remoteImagePath"
                            style="border-radius: 3px; width: 80px; height: auto; margin:0%;"
                            class="img-fluid rd"
                            @error="noImgUrl"
                          />
                          <div
                            v-else
                            style="border-radius: 3px; width: 80px; height: auto;"
                            :style="{
                              'aspect-ratio': '4 / 3',
                              backgroundColor: item.indexColor,
                            }"
                          ></div>
                        </div>
                      </td>
                      <td class="width: 30%">
                        {{ item.SKUName }} <img  v-if="item.sourceGRAB === 'GRAB'" src="/img/delivery/grab.png" class="rounded-circle"
                        style="border-radius: 3px; width: 18px;" />
                        <br/>
                        <div>
                          <small>
                            <span>
                              <CBadge v-if="item.vatType === 'V'" color="success">VAT</CBadge>
                              <CBadge>
                                <img v-if="item.source == 'SHOPEE'" src="/img/shopee.png" alt="Shopee Logo" style="width: 16px; height: 16px; object-fit: contain;"/>
                              </CBadge>
                            </span>
                          </small>
                        </div>
                        <div v-if="checkStdCost(item)" style="color: red; font-size: 12px;">
                          * {{ $t('stdCostWarning') }}
                        </div>
                      </td>
                      <td class="width: 10%">{{ item.unit }}</td>
                      <td class="width: 10%">{{ item.price }}</td>
                      <td class="width: 10%">{{ item.skuType }}</td>
                      <td class="width: 20%">{{ item.category }}</td>
                      <td class="width: 10%">
                        <router-link
                          :to="{ 
                            name: 'EcommerceProductDetail', 
                            params: { productSKUObjectId: item.objectId }, 
                            query: queryparams 
                          }"
                          style="text-decoration: none;"
                        >
                          
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <CListGroupItem v-if="loadingButton === false">
                  <div class="text-center" width="100%">
                    <button class="btn" type="button" disabled style="color: darkcyan">
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ $t("loading") }}
                    </button>
                  </div>
                </CListGroupItem>
              </div>
              <div v-else>
                <CRow v-if="!isEcommercePage">
                  <CCol
                    lg="2"
                    sm="3"
                    md="3"
                    col="6"
                    v-for="(item, index) in items"
                    :key="index"
                    hover
                  >
                    <router-link
                      style="text-decoration: none"
                      :to="{
                        name: 'ProductDetail',
                        params: { productSKUObjectId: item.objectId },
                        query: queryparams,
                      }"
                    >
                      <CCard class="list cursor">
                        <div class="display">
                          <div class="display-type">
                            <CBadge
                              color="light"
                              shape="pill"
                              class="text-dark font-weight-normal"
                            >
                              {{ item.skuType }}
                            </CBadge>
                          </div>
                          <div class="display-type-favorite">
                            <div v-if="item.favorite">
                              <CBadge
                                color="light"
                                shape="pill"
                                style="background-color: #fff; padding: 2px; border-radius: 50%; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; text-align: center;"
                              >
                                <i class="fi fi-rr-circle-heart" style="color: lightcoral; font-size: 16px; margin: auto;"></i>
                              </CBadge>
                            </div>
                          </div>
                          <img
                            class="card-img-top rd img-fluid"
                            v-if="item.remoteImagePath"
                            :src="item.remoteImagePath"
                            @error="noImgUrl"
                          />
                          <svg
                            class="bd-placeholder-img card-img-top rd"
                            v-else
                            title="gggg"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMidYMid slice"
                            role="img"
                          >
                            <rect width="100%" height="100%" :fill="item.indexColor" />
                          </svg>
                        </div>
                        <CCardBody style="padding: 0.2rem">
                          <table style="width: 100%">
                            <tr>
                              <td valign="top">
                                <h6 class="text-black font-weight-normal limit-2">
                                  {{ item.SKUName }} <img v-if="item.sourceGRAB === 'GRAB'" src="/img/delivery/grab.png" class="rounded-circle"
                                  style="width: 18px;" />
                                </h6>
                              </td>
                              <td style="width: 35%" valign="top">
                                <p class="text-right text-dark" style="font-size: 12px">
                                  {{ item.unit }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h6
                                  class="text-muted font-weight-normal text-left limit-1"
                                  style="font-size: 12px"
                                >
                                  <span>
                                    {{ item.category }}
                                  </span>
                                </h6>
                              </td>
                              <td>
                                <h6 class="text-right" style="font-size: 12px; display: flex">
                                  <span>
                                    <CBadge v-if="item.vatType === 'V'" color="success">
                                      VAT
                                    </CBadge>
                                  </span>
                                  <!-- &emsp; -->
                                  <span>
                                    <CBadge
                                      v-if="item.isOnScreen"
                                      color="orange"
                                      style="margin-left: 2px"
                                    >
                                      <i
                                        v-if="item.isOnScreen"
                                        class="fi fi-rr-computer"
                                        style="color: #fff"
                                      ></i>
                                    </CBadge>
                                  </span>
                                </h6>
                              </td>
                            </tr>
                            <tr style="border-top: 1px solid #ececec">
                              <td style="width: 90%;">
                                <h6 class="font-weight-normal text-success mt-2">
                                  ฿ {{ item.price }}
                                </h6>
                                <span v-if="checkStdCost(item)" class="text-danger" style="font-size: 10px;">
                                  * {{ $t('stdCostWarning') }}
                                </span>
                              </td>
                              <td class="text-right">
                                <CBadge v-if="isEditData">
                                  <i class="fi fi-rr-edit" style="color: darkgray"></i>
                                </CBadge>
                              </td>
                            </tr>
                          </table>
                        </CCardBody>
                      </CCard>
                    </router-link>
                  </CCol>
                </CRow>
                <CRow v-else>
                  <CCol
                    lg="2"
                    sm="3"
                    md="3"
                    col="6"
                    v-for="(item, index) in items"
                    :key="index"
                    hover
                  >
                    <router-link
                        style="text-decoration: none"
                        :to="{
                          name: 'EcommerceProductDetail',
                          params: { productSKUObjectId: item.objectId },
                          query: queryparams,
                        }"
                      >
                      <!-- <router-link
                        style="text-decoration: none"
                        :to="{
                          name: 'EcommerceProductDetail',
                        }"
                      > -->
                      <CCard class="list cursor">
                        <div class="display">
                          <div class="display-type">
                            <CBadge
                              color="light"
                              shape="pill"
                              class="text-dark font-weight-normal"
                            >
                              {{ item.skuType }}
                            </CBadge>
                          </div>
                          <div class="display-type-favorite">
                            <div v-if="item.favorite">
                              <CBadge
                                color="light"
                                shape="pill"
                                style="color: lightcoral"
                              >
                              <i class="fi fi-rr-circle-heart" style="color: lightcoral; font-size: 16px; margin: auto;"></i>
                              </CBadge>
                            </div>
                          </div>
                          <img
                            class="card-img-top rd img-fluid"
                            v-if="item.remoteImagePath"
                            :src="item.remoteImagePath"
                            @error="noImgUrl"
                          />
                          <svg
                            class="bd-placeholder-img card-img-top rd"
                            v-else
                            title="gggg"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMidYMid slice"
                            role="img"
                          >
                            <rect width="100%" height="100%" :fill="item.indexColor" />
                          </svg>
                        </div>
                        <CCardBody style="padding: 0.2rem">
                          <table style="width: 100%">
                            <tr>
                              <td valign="top">
                                <h6 class="text-black font-weight-normal limit-2">
                                  {{ item.SKUName }} <img v-if="item.sourceGRAB === 'GRAB'" src="/img/delivery/grab.png" class="rounded-circle"
                                  style="border-radius: 3px; width: 19px;" />
                                </h6>
                              </td>
                              <td style="width: 35%" valign="top">
                                <p class="text-right text-dark" style="font-size: 12px">
                                  {{ item.unit }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h6
                                  class="text-muted font-weight-normal text-left limit-1"
                                  style="font-size: 12px"
                                >
                                  <span>
                                    {{ item.category }}
                                  </span>
                                </h6>
                              </td>
                              <td>
                                <h6 class="text-right" style="font-size: 12px">
                                  <!-- &emsp; -->
                                  <span>
                                    <CBadge v-if="item.isOnScreen">
                                    <img src="/img/shopee.png"
                                        alt="Shopee Logo"
                                        style="width: 16px; height: 16px; object-fit: contain;"
                                    />
                                    </CBadge>
                                  </span>
                                  <span>
                                    <CBadge v-if="item.vatType === 'V'" color="success">
                                      VAT
                                    </CBadge>
                                  </span>
                                </h6>
                              </td>
                            </tr>
                            <tr style="border-top: 1px solid #ececec">
                              <td style="width: 90%">
                                <h6 class="font-weight-normal text-success mt-2">
                                  ฿ {{ item.price }}
                                </h6>
                                <span
                                  v-if="checkStdCost(item)"
                                  class="text-danger"
                                  style="font-size: 10px"
                                >
                                  * {{ $t("stdCostWarning") }}
                                </span>
                              </td>
                              <td class="text-right">
                                <CBadge v-if="isEditData">
                                  <i class="fas fa-pen" style="color: darkgray"></i>
                                </CBadge>
                              </td>
                            </tr>
                          </table>
                        </CCardBody>
                      </CCard>
                    </router-link>
                  </CCol>
                </CRow>
                <CRow v-else>
                  <CCol
                    lg="2"
                    sm="3"
                    md="3"
                    col="6"
                    v-for="(item, index) in items"
                    :key="index"
                    hover
                  >
                    <router-link
                        style="text-decoration: none"
                        :to="{
                          name: 'EcommerceProductDetail',
                          params: { productSKUObjectId: item.objectId },
                          query: queryparams,
                        }"
                      >
                      <!-- <router-link
                        style="text-decoration: none"
                        :to="{
                          name: 'EcommerceProductDetail',
                        }"
                      > -->
                      <CCard class="list cursor">
                        <div class="display">
                          <div class="display-type">
                            <CBadge
                              color="light"
                              shape="pill"
                              class="text-dark font-weight-normal"
                            >
                              {{ item.skuType }}
                            </CBadge>
                          </div>
                          <div class="display-type-favorite">
                            <div v-if="item.favorite">
                              <CBadge
                                color="light"
                                shape="pill"
                                style="color: lightcoral"
                              >
                                <i class="fas fa-heart"></i>
                              </CBadge>
                            </div>
                          </div>
                          <img
                            class="card-img-top rd img-fluid"
                            v-if="item.remoteImagePath"
                            :src="item.remoteImagePath"
                            @error="noImgUrl"
                          />
                          <svg
                            class="bd-placeholder-img card-img-top rd"
                            v-else
                            title="gggg"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMidYMid slice"
                            role="img"
                          >
                            <rect width="100%" height="100%" :fill="item.indexColor" />
                          </svg>
                        </div>
                        <CCardBody style="padding: 0.2rem">
                          <table style="width: 100%">
                            <tr>
                              <td valign="top">
                                <h6 class="text-black font-weight-normal limit-2">
                                  {{ item.SKUName }} <img v-if="item.sourceGRAB === 'GRAB'" src="/img/delivery/grab.png" class="rounded-circle"
                                  style="border-radius: 3px; width: 18px;" />
                                </h6>
                              </td>
                              <td style="width: 35%" valign="top">
                                <p class="text-right text-dark" style="font-size: 12px">
                                  {{ item.unit }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h6
                                  class="text-muted font-weight-normal text-left limit-1"
                                  style="font-size: 12px"
                                >
                                  <span>
                                    {{ item.category }}
                                  </span>
                                </h6>
                              </td>
                              <td>
                                <h6 class="text-right" style="font-size: 12px">
                                  <!-- &emsp; -->
                                  <span>
                                    <CBadge>
                                <img v-if="item.source == 'SHOPEE'" src="/img/shopee.png" alt="Shopee Logo" style="width: 16px; height: 16px; object-fit: contain;"/>
                              </CBadge>
                                  </span>
                                  <span>
                                    <CBadge v-if="item.vatType === 'V'" color="success">
                                      VAT
                                    </CBadge>
                                  </span>
                                </h6>
                              </td>
                            </tr>
                            <tr style="border-top: 1px solid #ececec">
                              <td style="width: 90%">
                                <h6 class="font-weight-normal text-success mt-2">
                                  ฿ {{ item.price }}
                                </h6>
                                <span
                                  v-if="checkStdCost(item)"
                                  class="text-danger"
                                  style="font-size: 10px"
                                >
                                  * {{ $t("stdCostWarning") }}
                                </span>
                              </td>
                              <td class="text-right">
                                <CBadge v-if="isEditData">
                                  <i class="fi fi-rr-edit" style="color: darkgray"></i>
                                </CBadge>
                              </td>
                            </tr>
                          </table>
                        </CCardBody>
                      </CCard>
                    </router-link>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getProduct"> </pagination>
        </CCardBody>
      </CCard>
      <CRow>
        <CModal color="danger" :show.sync="deleteModal" centered>
          <h4 class="text-center">{{ $t("dialigDeleteProduct") }}</h4>
          <br />
          <h5 class="text-center">{{ $t("confirmDeleteProduct") }}</h5>
          <template #footer>
            <div class="mx-auto">
              <CCol col="12">
                <CButton color="danger" @click="deleteUnit(itemDelete)">
                  {{ $t("submit") }}
                </CButton>
              </CCol>
            </div>
            <div class="mx-auto">
              <CButton color="light" @click="deleteModal = false">
                {{ $t("cancel") }}
              </CButton>
            </div>
          </template>
        </CModal>
      </CRow>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import util from "@/util/util";
import pos from "@/services/local";
import axios from "@/services/service";
import Pagination from "@/containers/Pagination";
import Sortdata from "@/containers/SortData";
import permis from "@/util/permission";
import SearchPattern from "@/containers/SearchPattern";
import { CListGroupItem } from "@coreui/vue";
import HelpButton from "../../containers/HelpButton.vue";

export default {
  components: {
    Pagination,
    Sortdata,
    SearchPattern,
    HelpButton
  },
  data() {
    return {
      isEcommercePage: false,
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: 50,
        itemCount: 0,
      },
      loadingButton: true,
      deleteModal: false,
      actionitem: false,
      sortdata: this.$route.query.sort || "SKUName",
      countProduct: 0,
      sort_value: {
        name: "SKUName",
        updated_at: "updated_at",
        category: "category.name",
        sortByNumMin: "sortByNumMin",
        sortByNumMax: "sortByNumMax",
      },
      showView: this.isEcommercePage ? 'list' : (this.$route.query.view || "grid"),
      view: "",
      keyword: this.$route.query.keyword || "",
      searchBy: this.$route.query.searchBy || "",
      deleteModal: false
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date"]),
    isPermission() {
      return permis.findPermissionRead("product", this.$route.path);
    },
    isEditData() {
      const value = permis.findPermissionEdit("product", this.$route.path);
      return value; // && this.editItem
    },
    editItem() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.editableItem || false;
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.uid;
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata;
        if (sortdata == "sortByNumMin" || sortdata == "sortByNumMax") {
          sortdata = "SKUPrice";
        }
        return sortdata;
      },
      set(newValue) {
        return newValue;
      },
    },
    itempage() {
      return this.meta_data.current_page * 50 - 50;
    },
    items() {
      let data = this.data;
      let detail = [];
      let categoryName = "-";
      let onhandQty = 0;
      let PLUCode = "-";
      let img = "";
      let unit = "-";
      let source = '-'
      let sourceGRAB =  ''

      for (let i = 0; i < data.length; i++) {
        if (data[i].skuType === "SV" || data[i].skuType === "BOM") {
          onhandQty = "non-stock products";
        } else {
          if (data[i].onhandQty !== undefined) {
            onhandQty = data[i].onhandQty;
          }
        }
        if (data[i].category !== undefined) {
          if (data[i].category.name !== undefined) {
            categoryName = data[i].category.name;
          }
        }
        if (data[i].category !== undefined) {
          if (data[i].category.source !== undefined) {
            source = data[i].category.source;
          }else{
            source = '-'
          }
        }
        if (data[i].productPLU !== undefined) {
          if (data[i].productPLU.PLUCode !== undefined) {
            PLUCode = data[i].productPLU.PLUCode;
          }
        }
        if (data[i].remoteImagePath === undefined) {
          img = undefined;
        } else if (data[i].remoteImagePath == "") {
          img = undefined;
        } else {
          img = data[i].remoteImagePath;
        }

        if (data[i].unit !== undefined) {
          if (data[i].unit.name !== undefined) {
            unit = data[i].unit.name;
          }
        }
        let isMenu = false;
        if (data[i].isMenu != undefined) {
          isMenu = data[i].isMenu;
        }

        if(data[i].source === 'GRAB'){
          sourceGRAB = 'GRAB'
        }else{
          sourceGRAB = ''
        }
        
        let stdCost = data[i].stdCost !== undefined ? data[i].stdCost : 0;

        detail.push({
          SKUName: data[i].SKUName,
          unit: unit,
          onhandQty: util.convertNumber(onhandQty),
          price: util.convertCurrency(data[i].SKUPrice),
          productSKUObjectId: data[i].objectId,
          PLUCode: PLUCode,
          category: categoryName,
          isOnScreen: data[i].isOnScreen,
          favorite: data[i].favorite,
          skuType: data[i].skuType,
          objectId: data[i].objectId,
          indexColor: util.generateColor(data[i].indexColor),
          remoteImagePath: img,
          vatType: data[i].vatType,
          sortPrice: data[i].SKUPrice,
          created_at: data[i].created_at,
          isMenu: isMenu,
          stdCost: stdCost,
          source: source,
          sourceGRAB : sourceGRAB
        });
      }
      return detail;
    },
    queryparams() {
      return {
        sort: this.sortdata,
        page: this.meta_data.current_page,
        view: this.showView,
        searchBy: this.searchBy,
        keyword: this.keyword,
      };
    },
    filteredItems() {
      if (this.isEcommercePage) {
        return this.items.filter(item => item.source === 'SHOPEE');
      } else {
        return this.items;
      }
    },
    isDeleteData() {
      let path = '/product'
      return permis.findPermissionRemove('product', path)
    },
  },
  created() {
    this.getProduct()
    this.shop = this.shops.find((i) => this.shopObjectId === i.objectId);
    if (this.shop) {
      this.isEcommercePage = this.shop.connectEcommerce;
    }
  },
  watch: {
    shop(newVal) {
    if (newVal && newVal.connectEcommerce !== undefined) {
      this.isEcommercePage = newVal.connectEcommerce;
    }
  }
  },
  mounted() {
    if (
      (this.shop?.connectEcommerce === undefined || this.shop?.connectEcommerce !== true) &&
      this.$route.path === '/ecommerce/ProductList'
    ) {
      this.$router.push('/product');
    } else if (
      (this.shop?.connectEcommerce != undefined || this.shop?.connectEcommerce === true )&&
      this.$route.path === '/product'
    ) {
      this.$router.push('/ecommerce/ProductList');
    }
  },
  methods: {
    ...util,
    deleteProduct() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const shopObjectId = this.shopObjectId
      let data = {
        shopObjectId: shopObjectId,
        objectId: this.productSKUObjectId,
      }
      const headers = { shopObjectId: shopObjectId }

      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/ProductSKU/softdelete',
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.deleteModal = false
          }, 100)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    selectProduct() {
      this.$router.push('/ecommerce/SelectProduct');
    },
    checkStdCost(item) {
    return  item.stdCost > item.sortPrice && item.sortPrice > 0;
    },
    toAddProduct() {
      this.$router.push("/product/newAddproduct");
    },
    toImportProduct() {
      this.$router.push("/product/importProduct");
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE;
    },
    getChange(value) {
      this.showView = value;
    },
    confirmdelete(item) {
      this.deleteModal = true;
      this.itemDelete = item;
    },
    reset() {
      this.keyword = "";
      this.getProduct();
    },
    deleteUnit(item) {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const plan = this.users.currentPlan;
      const shopObjectId = this.shopObjectId;
      let data = {
        shopObjectId: shopObjectId,
        objectId: item,
      };
      const headers = { shopObjectId: shopObjectId };

      pos({
        method: "post",
        url: "/api/v1.0/" + uid + "/ProductSKU/softdelete",
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.deleteModal = false;
            this.getProduct();
          }, 1000)
        )
        .catch((error) => {
          console.log(error);
        });
    },
    productDetail(item) {
      let productSKUObjectId = item.productSKUObjectId;
      this.$store.dispatch('setProductID', productSKUObjectId)
      this.$store.dispatch('getProductSKUDatail')
      
      this.$router.push({
        name: "ProductDetail",
        params: { productSKUObjectId },
        query: {
          sort: this.sortdata,
          page: this.meta_data.current_page,
          view: this.showView,
        },
      });
    },
    setSort(value) {
      this.sortdata = value;
      this.getProduct();
    },
    setSearch(searchBy, keyword) {
      this.keyword = keyword;
      this.searchBy = searchBy;
      this.getProduct();
    },
    getProduct(page = this.$route.query.page || 1) {
      this.searchLoadingButton = false;
      this.loadingButton = false;
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const headers = { shopObjectId: shopObjectId };

      let sortOrder = "";
      if (this.sortdata == "sortByNumMin") {
        sortOrder = "asc";
      } else if (this.sortdata == "sortByNumMax" || this.sortdata == "updated_at") {
        sortOrder = "desc";
      } else {
        sortOrder = "asc";
      }

      let params = {
        shopObjectId: shopObjectId,
        page: page,
        sortattr: this.sortAttr,
        sortorder: sortOrder,
        limit: this.meta_data.limit,
      };
      // Add search attribute.
      if (this.searchBy !== "") {
        params["searchVal"] = this.keyword;
        params["searchAttr"] = this.searchBy;
      }
      axios({
        url: "/shop/v1.0/" + uid + "/getproduct/data",
        params: params,
        headers: headers,
        method: "GET",
      }).then((res) => {
        this.data = res.data.data;
        if (res.data.paginate.items == undefined) {
          this.countProduct = 0;
          this.meta_data.items = 0;
        } else {
          this.countProduct = res.data.paginate.items;
          this.meta_data.items = res.data.paginate.items;
        }
        this.meta_data.last_page = res.data.paginate.pageCount;
        this.meta_data.current_page = res.data.paginate.currentPage;
        this.meta_data.itemCount = res.data.paginate.itemCount;
        this.meta_data.limit = res.data.paginate.perPage;
        this.countlist = res.data.length;
        this.searchLoadingButton = true;
        this.loadingButton = true;
      });
    },
    addProduct() {
      this.$router.push({
        name: "newAddproduct",
        query: {
          sort: this.sortdata,
          page: this.meta_data.current_page,
          view: this.showView,
        },
      });
    },
  },
  
};
</script>
<style>
.form-control {
  cursor: pointer;
}
.custom-select {
  cursor: pointer;
}
.form-control:disabled,
.form-control[readonly] {
  cursor: default;
}
.view-list {
  border-radius: 0.2rem;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 1px;
}

.view-icon:active {
  color: #29b289;
  background-color: white;
}
label.btn.form-check-label.col-lg-6.btn-ghost-secondary.view-icon.active {
  background-color: white;
  color: #29b289;
}
.list {
  box-shadow: 0 0 0 1px #e5e5e5;
}
.list:hover {
  box-shadow: 0 12px 40px 0 rgb(0 0 0 / 16%);
  cursor: pointer;
}
.posti {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1px;
  height: 16px;
  background-color: #dcdce0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.rd {
  aspect-ratio: 4 / 3;
  object-fit: cover;
}
.btn-reset {
  box-shadow: 0 0 0 1px #e5e5e5;
}
.btn-reset:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.display {
  position: relative;
  text-align: center;
  color: white;
}
.display-type {
  font-size: 16px !important;
  position: absolute;
  top: 3px;
  right: 5px;
}
.display-type-favorite {
  position: absolute;
  bottom: 3px;
  right: 5px;
  font-size: 15px !important;
}
.label-product {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 32px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.lo-label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */
}
.lo-label-unit {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */
}
.unit-label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: darkslategray;
  text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */
}
.icon-css {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 4px;
  padding-left: 0px;
}
.limit-2 {
  line-height: 20px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.limit-1 {
  line-height: 20px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
